import React, {
  useState,
  useEffect,
  useRef,
} from 'react'
import {
  Typography, Box, IconButton,
} from '@material-ui/core'
import classes from './InfoBar.styles'
import {
  Close, ExpandLess, Help,
} from '@material-ui/icons'
import Countdown, {
  CountdownRenderProps, zeroPad,
} from 'react-countdown'
import commonStyling from '../../styles/commonStyling'
import theme, { COLORS } from '../../styles/theme'
import InfoBarDropdown from './InfoBarDropdown'
import { cx } from 'linaria'
import StyledButton, { ButtonProps } from '../StyledButton/StyledButton'
import { parseDateToUTC } from '../../utils/parseDateToUTC'

export interface InfoBarProps {
  meta?: {
    id?: string;
  };
  color?: 'error' | 'success' | undefined;
  sticky_banner?: boolean;
  start_date: string;
  end_date?: string;
  text?: string;
  button?: ButtonProps;
  dropdown_text?: string;
  dropdown_button?: ButtonProps;
  countdown?: boolean;
}

export const renderButtonProps = (button: ButtonProps) => ({
  color: button?.color,
  url: button.url,
  isModal: button?.modal,
  variant: button?.variant,
  size: button?.size,
  disable_padding: button?.disable_padding,
})

export default function InfoBar({
  countdown,
  meta,
  color,
  sticky_banner,
  start_date,
  end_date,
  text,
  button,
  dropdown_text,
  dropdown_button,
}: InfoBarProps) {
  const bannerRef = useRef<HTMLDivElement | null>(null)

  const [
    InfoBarVisible,
    setInfoBarVisible,
  ] = useState(true)

  const [
    bannerHeight,
    setBannerHeight,
  ] = useState(0)

  const [
    dropdownVisible,
    setDropdownVisible,
  ] = useState(false)

  const currentDate = parseDateToUTC()
  const parsedStartDate = start_date && parseDateToUTC(start_date)
  const parsedEndDate = end_date && parseDateToUTC(end_date)

  useEffect(() => {
    if (!InfoBarVisible) {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(`InfoBar-${meta?.id}`, 'hidden')
      }
    }
  }, [
    InfoBarVisible,
    meta,
  ])

  useEffect(() => {
    if (typeof sessionStorage !== 'undefined') {
      const closedInfoBar = sessionStorage.getItem(`InfoBar-${meta?.id}`)
      if (closedInfoBar) {
        setInfoBarVisible(false)
      }
    }
  }, [
    InfoBarVisible,
    meta,
  ])

  useEffect(() => {
    if (countdown && parsedEndDate && (currentDate < parsedStartDate || currentDate > parsedEndDate)) {
      setInfoBarVisible(false)
    }
  }, [
    countdown,
    currentDate,
    parsedEndDate,
    parsedStartDate,
  ])

  useEffect(() => {
    const handleResize = () => {
      if (bannerRef.current) {
        setBannerHeight(bannerRef.current.clientHeight)
      }
    }

    if (sticky_banner) {
      handleResize()

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }

    return undefined
  }, [sticky_banner])

  if (!InfoBarVisible) {
    return null
  }

  const toggleDropDown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  const createColor = (color?: string) => {
    if (color === 'info') {
      return COLORS.blue
    }

    if (color === 'error') {
      return COLORS.red
    }

    if (color === 'success') {
      return COLORS.green
    }

    if (color === 'secondary') {
      return COLORS.secondary
    }

    return COLORS.primary
  }

  const hideInfoBar = () => setInfoBarVisible(false)

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      return null
    }

    return (
      <Box display="flex" alignItems="center" gridGap={5}>
        <Box {...countDownItemProps}>
          <Typography className={classes.countdownNumber} variant="body1" color="inherit">{zeroPad(days)}</Typography>
          <Typography className={classes.countdownLabel} color="inherit">Days</Typography>
        </Box>
        <Box {...countDownItemProps}>
          <Typography className={classes.countdownNumber} variant="body1" color="inherit">{zeroPad(hours)}</Typography>
          <Typography className={classes.countdownLabel} color="inherit">Hours</Typography>
        </Box>
        <Box {...countDownItemProps}>
          <Typography className={classes.countdownNumber} variant="body1" color="inherit">{zeroPad(minutes)}</Typography>
          <Typography className={classes.countdownLabel} color="inherit">Min</Typography>
        </Box>
        <Box {...countDownItemProps}>
          <Typography className={classes.countdownNumber} variant="body1" color="inherit">{zeroPad(seconds)}</Typography>
          <Typography className={classes.countdownLabel} color="inherit">Sec</Typography>
        </Box>
      </Box>
    )
  }

  const countDownItemProps = {
    bgcolor: 'rgba(255,255,255,0.1)',
    borderRadius: commonStyling.smallRoundedBorders.all,
    boxSizing: 'border-box',
    p: 0.5,
    textAlign: 'center',
    width: `${theme.spacing(5)}px`,
  }

  const flexProps = {
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <>
      <div
        className={cx(classes.container, sticky_banner && 'sticky')}
        id="infobar_top"
        ref={bannerRef}
        style={{
          backgroundColor: createColor(color),
        }}
      >
        <Box
          justifyContent="center"
          position="relative"
          {...flexProps}
        >
          <Box
            justifyContent="center"
            gridGap={{
              xs: 16,
              md: 24,
            }}
            pl={{
              xs: 0.5,
              md: 6,
            }}
            pr={6}
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
            {...flexProps}
          >
            <Box
              gridGap={{
                xs: 2,
                md: 16,
              }}
              flexDirection={{
                xs: 'column',
                md: 'row',
              }}
              {...flexProps}
            >
              {text && <Typography color="inherit" variant="body1">{text}</Typography>}
              {button && button?.text &&
                <StyledButton
                  {...renderButtonProps(button)}
                >
                  {button.text}
                </StyledButton>}
            </Box>
            {countdown && parsedEndDate &&
              <Countdown
                date={parsedEndDate}
                renderer={renderer}
                onComplete={hideInfoBar}
              />}
          </Box>
          <Box
            component="nav"
            gridGap={8}
            height="100%"
            position="absolute"
            right={0}
            top={0}
            flexDirection={{
              xs: 'column-reverse',
              md: 'row',
            }}
            justifyContent={{
              xs: 'flex-end',
              md: 'center',
            }}
            {...flexProps}
          >
            {dropdown_text &&
            <IconButton aria-label="support-info-bar" size="small" onClick={toggleDropDown} color="inherit">
              {dropdownVisible ? <ExpandLess/> : <Help/>}
            </IconButton>}
            <IconButton aria-label="close-info-bar" size="small" onClick={hideInfoBar} color="inherit">
              <Close/>
            </IconButton>
          </Box>
        </Box>
        {dropdown_text &&
          <InfoBarDropdown
            text={dropdown_text}
            button={dropdown_button}
            visible={dropdownVisible}
          />}
      </div>
      {sticky_banner &&
        <Box
          style={{
            height: `${bannerHeight}px`,
          }}
        />}
    </>
  )
}
