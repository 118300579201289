import React from 'react'
import Image from 'next/image'
import Head from 'next/head'
import {
  css, cx,
} from 'linaria'
import {
  Box, useMediaQuery,
} from '@material-ui/core'
import theme, { ROUNDED } from '../../styles/theme'

/**
 * Determine if image should be treated as small decorative based on dimensions
 * Using a conservative estimate of 1 byte per pixel
 */
const isSmallDecorativeImage = (width: number, height: number): boolean => {
  const estimatedSizeKB = (width * height) / 1024 // Simple 1 byte per pixel estimate
  return estimatedSizeKB <= 30
}

export interface MediaImageProps {
  readonly src: string;
  readonly width: number;
  readonly height: number;
  readonly mobile_src?: string;
  readonly mobile_width?: number;
  readonly mobile_height?: number;
  readonly alt?: string;
  readonly use_img_for_seo?: boolean;
  readonly full_width?: boolean;
  readonly full_height?: boolean;
  readonly fixed_height?: boolean;
  readonly className?: string;
  readonly center?: boolean;
  readonly background?: boolean;
  readonly cover?: boolean;
  readonly align?: 'top' | 'center' | 'bottom';
  readonly fit?: 'cover' | 'contain';
}

const classes = {
  mediaWrapper: css`
    div {
      height: 100%;
    }
    img {
      border-radius: ${ROUNDED.medium}px;
    }
  `,
}

export const createMediaImageProps = (image: MediaImageProps, width: number, height: number) => ({
  src: image.src,
  alt: image.alt,
  width: image.width ? image.width : width,
  height: image.height ? image.height : height,
  mobile_src: image.mobile_src,
  mobile_width: image.mobile_width,
  mobile_height: image.mobile_height,
  fit: image.fit,
  full_width: image?.full_width,
  full_height: image?.full_height,
  align: image?.align,
})

export default function MediaImage({
  src,
  alt,
  width,
  height,
  mobile_src,
  mobile_width,
  mobile_height,
  use_img_for_seo,
  full_width,
  full_height,
  fixed_height,
  className,
  center,
  background,
  cover,
  fit,
  align = 'center',
}: MediaImageProps) {
  const getWrapperWidth = (width: number) => {
    let wrapperWidth
    if (full_width || background) {
      wrapperWidth = '100%'
    } else {
      wrapperWidth = `${width}px`
    }

    return wrapperWidth
  }

  const getWrapperHeight = (height: number) => {
    let wrapperHeight
    if (fixed_height) {
      wrapperHeight = `${height}px`
    } else if (full_height || background) {
      wrapperHeight = '100%'
    } else {
      wrapperHeight = 'auto'
    }

    return wrapperHeight
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const objectFit = (fit: 'cover' | 'contain') => {
    if (background || cover || fit === 'cover') {
      return 'cover'
    }

    if (fit === 'contain') {
      return 'contain'
    }

    return 'inherit'
  }

  return (
    <>
      {use_img_for_seo && (
        <Head>
          <meta property="og:image" content={src}/>
        </Head>
      )}
      <Box
        width={{
          xs: getWrapperWidth(mobile_width || width),
          md: getWrapperWidth(width),
        }}
        height={{
          xs: getWrapperHeight(mobile_height || height),
          md: getWrapperHeight(height),
        }}
        marginLeft={center ? 'auto' : 0}
        marginRight={center ? 'auto' : 0}
        maxWidth="100%"
        className={cx(classes.mediaWrapper, className)}
      >
        <Image
          src={isMobile && mobile_src ? mobile_src : src}
          alt={alt}
          width={isMobile && mobile_width ? mobile_width : width}
          height={isMobile && mobile_height ? mobile_height : height}
          layout="responsive"
          objectPosition={align}
          objectFit={objectFit(fit!)}
          sizes={isSmallDecorativeImage(
            isMobile && mobile_width ? mobile_width : width,
            isMobile && mobile_height ? mobile_height : height,
          ) ? '30vw' : '100vw'}
          priority={isSmallDecorativeImage(
            isMobile && mobile_width ? mobile_width : width,
            isMobile && mobile_height ? mobile_height : height,
          )}
        />
      </Box>
    </>
  )
}
