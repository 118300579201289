import React, { useState } from 'react'
import {
  getInstallationCost,
} from '../../utils/getProductDetails'
import {
  Box, Typography, Grid, GridSize, List, Avatar, ListItem, ListItemAvatar, ListItemText, IconButton,
} from '@material-ui/core'
import {
  withStyles, createStyles, Theme,
} from '@material-ui/core/styles'
import ModalBox from '../ModalBox/ModalBox'
import classesOld from './Packages.styles'
import StyledButton from '../StyledButton/StyledButton'
import {
  getRouterName, splitBullets,
} from '../../utils'
import { Check } from '@material-ui/icons'
import { HobsProduct } from '../../api/Packages'
import { Variant } from '@material-ui/core/styles/createTypography'
import theme from '../../styles/theme'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import MediaImage, {
  MediaImageProps,
} from '../Media/MediaImage'

interface FeaturesProps {
  columns?: number;
  columns_gap?: number;
  bullet_gap?: number;
  gap?: number;
  mt?: number;
  mb?: number;
  bullet_color?: 'action' | 'disabled' | 'error' | 'inherit' | 'primary' | 'secondary';
  text_color?: 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  size?: string;
  bullets_crm?: HobsProduct;
  bullet_out_of_contract_price?: string;
  free_set_up_copy?: string;
  bullets_with_icons?: BulletModalProps[];
  simple_bullets?: string;
  back_bullets?: string;
  bullet_size?: string;
  text_variant?: Variant;
  align?: string;
  no_bullets?: boolean;
  classes?: any;
}

export interface IconsWithTextProps {
  image: string;
  media_image: MediaImageProps;
  title: string;
  text: string;
}

export interface BulletModalProps {
  meta: {
    id: number;
  };
  bullet_icon: MediaImageProps;
  bullet_text_first_line: string;
  bullet_text_second_line: string;
  opens_a_modal: boolean;
  modal_title: string;
  modal_text: string;
  modal_icons: IconsWithTextProps[];
}

export const createBullet = (
  data: Object,
  color?: 'action' | 'disabled' | 'error' | 'inherit' | 'primary' | 'secondary',
  bullet_gap?: number,
  gap?: number,
  text_color?: 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error',
  size?: string,
  bullet_size?: string,
  align?: string,
  no_bullets?: boolean,
  text_variant?: Variant,
) => {
  return (
    Object.values(data)
      .map((el: HTMLElement, index: number) => {
        if (typeof (el) === 'string') {
          return (
            <Box key={index} component="li" display="flex" m={0} p={0} pt={index > 0 ? gap : 0} alignItems={(el as string).length > 150 ? 'baseline' : 'center'} justifyContent={align}>
              {!no_bullets &&
              <Typography
                color={text_color}
                style={{
                  display: 'flex',
                  fontSize: (size === 'large' || bullet_size === 'large') ? '24px' : '12px',
                  marginRight: theme.spacing(bullet_gap!),
                  position: 'relative',
                }}
              >
                <Check fontSize="inherit" color={color}/>
              </Typography>}
              <Typography
                variant={size === 'large' ? 'body1' : text_variant}
                color={text_color}
                className={size === 'large' ? classesOld.fontWeightNormal : undefined}
              >
                {el}
              </Typography>
            </Box>
          )
        }

        return (
          <Box key={index} component="li" display="flex" m={0} p={0} pt={index > 0 ? gap : 0} alignItems="center" justifyContent={align}>
            {el}
          </Box>
        )
      },
      )
  )
}

const FeaturesStyles = (theme: Theme) =>
  createStyles({
    features: ({
      mb, mt, columns, columns_gap, gap,
    }: FeaturesProps) => ({
      ...(columns && {
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }),
      ...(mb && { marginBottom: theme.spacing(mb) }),
      ...(mt && { marginTop: theme.spacing(mt) }),
      [theme.breakpoints.up('xs')]: {
        gridGap: theme.spacing(gap || 1.5),
      },
      [theme.breakpoints.up('sm')]: {
        gridGap: theme.spacing(columns_gap || 5),
      },
    }),
  })

const Features = withStyles(FeaturesStyles)(({
  columns = 1, bullet_color = 'secondary', bullet_gap = 2, gap = 1, text_color = 'textPrimary', size,
  bullets_crm, bullets_with_icons, simple_bullets, back_bullets, bullet_size, align, no_bullets, text_variant = 'overline', classes, bullet_out_of_contract_price, free_set_up_copy,
}: FeaturesProps) => {
  const [
    modalVisible,
    setModalVisible,
  ] = useState<number>()

  let crmBullets: any
  if (bullets_crm) {
    const routerName = getRouterName(bullets_crm)
    const installationCost = getInstallationCost(bullets_crm)
    const bulletInstallation = installationCost === '0.00' || installationCost === '0' ? free_set_up_copy : '£' + installationCost + ' set up fee'
    const bulletRouter = routerName + ' included'

    crmBullets = {
      ...installationCost && { bulletInstallation },
      ...routerName && { bulletRouter },
    }
  }

  const getBulletModals = (modals: BulletModalProps[]) => {
    return modals && modals.map((el: BulletModalProps, index: number) => {
      const openModal = () => {
        if (modalVisible !== el.meta.id) {
          return setModalVisible(el.meta?.id)
        }

        return setModalVisible(undefined)
      }

      return (
        <div key={index}>
          <List className={classesOld.root} disablePadding>
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar variant="square">
                  {el.bullet_icon.src ?
                    <MediaImage
                      src={el.bullet_icon.src}
                      width={24}
                      height={24}
                      alt={el.bullet_icon.alt}
                      align={el.bullet_icon.align}
                      fit={el.bullet_icon.fit}
                      cover={el.bullet_icon.cover}
                    /> :
                    <Check color={bullet_color}/>}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={el.bullet_text_first_line} secondary={el.bullet_text_second_line} primaryTypographyProps={{
                  variant: 'overline',
                  color: 'textPrimary',
                }}
                secondaryTypographyProps={{
                  variant: 'h6',
                  color: 'primary',
                }}
              />
              {el.opens_a_modal &&
              <Box display="flex" paddingLeft={0.5}>
                <IconButton aria-label="info" onClick={openModal}>
                  <InfoOutlinedIcon color="primary"/>
                </IconButton>
              </Box>}
            </ListItem>
          </List>
          {el.bullet_text_first_line && modalVisible &&
          <ModalBox
            visible={modalVisible === el.meta?.id}
            sync={openModal}
          >
            <Box mb={4}><Typography variant="h4" align="center" color="primary">{el.modal_title}</Typography></Box>
            <Typography dangerouslySetInnerHTML={{ __html: el.modal_text }} variant="overline" color="primary" component="div"/>
            {el.modal_icons && el.modal_icons.length > 0 &&
            <Box mt={4}>
              <Grid spacing={5} container>
                {Object.values(el.modal_icons)
                  .map((item: IconsWithTextProps, index: number) => {
                    const getColumn = (column: number): GridSize => 12 / column as GridSize

                    return (
                      <Grid key={index} item xs={12} sm={getColumn(el.modal_icons.length)} className={classesOld.iconWithText}>
                        {item.media_image && item.media_image?.src &&
                        <MediaImage
                          src={item.media_image.src}
                          width={item.media_image.width || 64}
                          height={item.media_image.height || 64}
                          alt={item.media_image.alt}
                          mobile_src={item.media_image.mobile_src}
                          mobile_width={item.media_image.mobile_width}
                          mobile_height={item.media_image.mobile_height}
                          use_img_for_seo={item.media_image.use_img_for_seo}
                          center
                        />}
                        {item.image &&
                        <img
                          src={item.image}
                          alt={item.title}
                          height="40"
                          width="40"
                        />}
                        <Box mt={1}>
                          {item.title && <Typography variant="h6" color="primary" align="center" gutterBottom>{item.title}</Typography>}
                          {item.text && <Typography variant="overline" component="div" color="primary" align="center" dangerouslySetInnerHTML={{ __html: item.text }}/>}
                        </Box>
                      </Grid>
                    )
                  })}
              </Grid>
            </Box>}
            <Box display="flex" justifyContent="center" mt={3} pb={4}>
              <StyledButton color="secondary" variant="outlined" handleClick={openModal}>Close</StyledButton>
            </Box>
          </ModalBox>}
        </div>
      )
    })
  }

  const sliceBullets = (arr: Array<unknown>, itemsPerColumn: number) => {
    if (!itemsPerColumn && itemsPerColumn < 1) {
      return arr
    }

    const res = []
    for (let i = 0; i < arr.length; i += itemsPerColumn) {
      const chunk = arr.slice(i, i + itemsPerColumn)
      res.push(chunk)
    }

    return res
  }

  const mergedBullets = [] as Array<unknown>
  const addBullets = (bullets: any[]) => {
    return bullets && Object.values(bullets)
      .forEach((e: any) => mergedBullets.push(e))
  }

  bullets_with_icons && addBullets(getBulletModals(bullets_with_icons))
  bullets_crm && addBullets(crmBullets)
  simple_bullets && addBullets(splitBullets(simple_bullets))
  back_bullets && addBullets(splitBullets(back_bullets))

  if (bullets_crm!) {
    bullet_out_of_contract_price && addBullets([bullet_out_of_contract_price])
  }

  const bulletColumns = sliceBullets(mergedBullets.filter(Boolean), Math.ceil(mergedBullets.length / columns))

  return (
    <div className={classes.features}>
      {Object.values(bulletColumns)
        .map((e: Object, index) => (
          <Box
            key={index}
            component="ul"
            m={0}
            pl={0.5}
          >
            {createBullet(e, bullet_color, bullet_gap, gap, text_color, size, bullet_size, align, no_bullets, text_variant)}
          </Box>
        ))}
    </div>
  )
})

export default Features
