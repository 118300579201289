import { css } from 'linaria'
import theme, {
  COLORS,
} from '../../styles/theme'
import fontSizes from '../../styles/fontSizes'
import commonStyling from '../../styles/commonStyling'

const classes = {
  container: css`
    text-align: center;
    display: flex;
    justify-content: center;
    .MuiGrid-spacing-xs-4 > .MuiGrid-item {
      width: 100%;
    }
  `,
  boxContainer: css`
    width: 100%;
    max-width: ${theme.breakpoints.values.lg}px;
    margin: auto;
  `,
  newVersionContainer: css`
    margin: 0 auto;
    display:flex;
    align-items:center;
    border:2px solid ${COLORS.secondary};
    border-radius:${theme.spacing(1)}px;
     a{
      text-decoration:none;
     }
  `,
  newVersionText: css`
    text-align: left;
`,
  styledText: css`
    color: ${COLORS.bodyTextPrimary};
    font-family: ${theme.typography.fontFamily!};
    font-size: ${fontSizes.body1};
    line-height: 1.5;
    margin: 0, auto;
    p {
      margin: auto;
    }
    ${theme.breakpoints.up('md')} {
      max-width: ${commonStyling.breakpoints.desktopSmall};
    }
  `,
  text: css`
    padding: 0 ${theme.spacing(2)}px;
    max-width: ${commonStyling.breakpoints.desktopSmall};
    .MuiTypography-body1 {
      line-height: 1.5;
      margin: 0 auto;
      font-weight: ${theme.typography.fontWeightLight!};
      color: ${COLORS.bodyTextPrimary};
      ${theme.breakpoints.up('md')} {
        max-width: ${commonStyling.breakpoints.desktopSmall};
      }
    }
  `,
  widerText: css`
    ${theme.breakpoints.up('md')} {
      max-width: 100%;
      .MuiTypography-body1 {
        max-width: 100%;
      }
   }
  `,
  animatedButtonIcon: css`
    .MuiButton-label {
      img {
        margin-left: 11px;
        position: relative;
        top: 1px;
        animation: bounce-icon .4s infinite alternate;
      }
      @keyframes bounce-icon {
        from {
          transform: translateX(0px);
        }
        to {
          transform: translateX(-5px);
        }
      }
    }
  `,
  buttons: css`
    width: 100%;
    .MuiButtonBase-root {
      margin: ${theme.spacing(1, 0)};
      ${theme.breakpoints.up('sm')} {
        margin: ${theme.spacing(0, 1)};
      }
    }
  `,
  media: css`
    max-width: ${commonStyling.breakpoints.desktopLarge};
    margin: 0;
  `,
  mediaIcon: css`
    ${theme.breakpoints.up('md')} {
      padding: 9px;
    }
    margin-right: ${theme.spacing(1)}px;
    display:flex;
    flex-direction: column;
    justify-content: center;
  `,
  mediaImage: css`
    margin-bottom: ${theme.spacing(4)}px;
    img{
      width: auto;
      max-width: 100%;
    }
  `,
  mediaVideo: css`
    margin-bottom: ${theme.spacing(4)}px;
`,
}

export default classes
