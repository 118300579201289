import { AxiosInstance } from 'axios'
import env from '../utils/env'
import { JsonObject } from 'type-fest'
import moment from 'moment'

export enum ScheduleType {
  INSTALL = 'INSTALL',
  SERVICE = 'SERVICE',
}

export interface TimeslotData extends JsonObject {
  _id: string;
  scheduleID: string;
  scheduleType: 'INSTALL';
  restrictions: {
    _id: string;
    pop: string;
    premiseTotal: string;
    scheduleTotal: number;
  }[];
  startDateTime: string;
  endDateTime: string;
  label?: string;
  appointmentWindowName?: string;
  appointmentWindowId?: string;
  date?: string;
  available?: boolean;
  appointmentWindowData: Timeslot[];
}

export type Timeslot = {
  _id: {
    startDate?: string;
    startDayOfWeek: number;
    startDayOfYear: number;
    startWeek: number;
    startMonth: number;
    startYear: number;
    startHour: number;
    startDateTime: string;
    endDateTime: string;
    endDayOfWeek?: number;
  };
  startDateTime: string;
  endDateTime: string;
  appointmentWindowName?: string;
  appointmentWindowId?: string;
  date?: string;
  available?: boolean;
  data: TimeslotData[];
};

export interface PendingTimeslot {
  _id: {
    startDayOfWeek: number;
    startWeek: number;
  };
  data: TimeslotData[];
}

export interface TimeslotError {
  message: string;
  error: {
    response: {
      data: {
        code: number;
      } | any;
    } | any;
  };
}

export interface ResponseProps {
  appointmentWindowData: Timeslot[];
  data: TimeslotData[];
}

export interface CreateTimeslotData {
  firstName?: string;
  lastName?: string;
  email?: string;
  contactPhone?: string;
  alternativePhone?: string;
  accountNumber?: string;
  tvFlag?: boolean;
  voiceFlag?: boolean;
  reservationId?: string;
  serviceType?: string;
  broadbandProduct?: string;
  customerId?: string;
  orderId?: string;
}

export function isTimeslotError(
  timeslot: TimeslotData | TimeslotError,
): timeslot is TimeslotError {
  return 'error' in timeslot
}

const BASE_URL = env.CRMSchedulerUrl

export default function BookingCalendar(client: AxiosInstance) {
  async function getTimeslots(
    fullPremise: any,
    startDate: Date,
    endDate: Date,
    payload: CreateTimeslotData,
    scheduleType: ScheduleType = ScheduleType.INSTALL,
  ): Promise<Timeslot[] | null> {
    try {
      // Create moment objects for start and end dates
      const finalStartMoment = moment(startDate)
      const finalEndMoment = moment(endDate)

      // Handle December to January transition
      if (finalStartMoment.month() === 11 && finalEndMoment.month() === 0) {
        finalEndMoment.year(finalStartMoment.year() + 1)
      }

      const finalStartDate = finalStartMoment.format('YYYY-MM-DD')
      const finalEndDate = finalEndMoment.format('YYYY-MM-DD')

      // If dates are invalid, return empty array instead of making API call
      if (moment(finalEndDate)
        .isBefore(finalStartDate)) {
        console.error('Error: endDate is before startDate.')
        return []
      }

      const params = {
        data: [{
          processId: 'cflReturnAvailableAppointmentWindows',
          apiResponseFormat: true,
          contextVar: {
            startDate: finalStartDate,
            endDate: finalEndDate,
            scheduleType,
            broadbandProduct: payload?.broadbandProduct?.replace(/[aA-zZ]/g, ''),
            serviceType: payload.serviceType,
            accountType: fullPremise?.type?.toUpperCase(),
            installationType: fullPremise?.installType || fullPremise?.install_type || 1,
            region: fullPremise.borough,
          },
        }],
      }

      const { data } = await client.post<{ data: ResponseProps[] }>(
        'appointment/get',
        params,
      )

      return data.data[0].appointmentWindowData || []
    } catch (err) {
      console.error('Error fetching timeslots:', err)
      return []
    }
  }

  async function getPendingTimeslots(accountId: string) {
    try {
      const { data } = await client.get<{ timeslots: TimeslotData[] }>(
        `v1/timeslot-account/${accountId}`,
        {
          baseURL: BASE_URL,
          responseType: 'json',
        },
      )

      return data.timeslots
    } catch (err) {
      return {
        error: err,
        message: 'Failed to retrieve pending timeslots',
      }
    }
  }

  async function rescheduleTimeslot({
    recentWorkorder,
    timeslot,
  }: { recentWorkorder: string; timeslot: TimeslotData | Timeslot }) {
    let startTimeInUTC = timeslot?.startDateTime
    let endTimeInUTC = timeslot?.endDateTime
    let appointmentWindowId = timeslot?.appointmentWindowName?.toLowerCase()
    if ('appointmentWindowData' in timeslot) {
      startTimeInUTC = timeslot?.appointmentWindowData[0].startDateTime
      endTimeInUTC = timeslot?.appointmentWindowData[0].endDateTime
      appointmentWindowId = timeslot?.appointmentWindowData[0]?.appointmentWindowName?.toLowerCase()
    }

    try {
      const params = {
        data: [{
          processId: 'cflBookAppointment',
          apiResponseFormat: true,
          contextVar: {
            workOrderTaskId: recentWorkorder,
            startTimeInUTC,
            endTimeInUTC,
            appointmentWindowId,
            action: 'rescheduleBooking',
          },
        }],
      }
      const { data } = await client.post<any>('appointment/reschedule', params)

      return data
    } catch (err) {
      return {
        error: err,
        message: 'Could not reschedule timeslot',
      }
    }
  }

  async function reserveTimeslot(
    timeslot: Timeslot,
    fullPremise: any,
    broadbandProduct: string | undefined,
    serviceType: string,
  ) {
    try {
      const params = {
        data: [{
          eventId: 'reserveAppointment',
          contextVar: {
            date: timeslot.date,
            appointmentWindowId: timeslot.appointmentWindowId,
            scheduleType: 'INSTALL',
            broadbandProduct: broadbandProduct?.replace(/[aA-zZ]/g, ''),
            serviceType,
            accountType: fullPremise?.type?.toUpperCase(),
            installationType: fullPremise?.installType || fullPremise?.install_type,
            region: fullPremise.borough,
          },
        }],
      }
      const { data } = await client.post<{ data: any[] }>('appointment/reserve', params)

      return data.data[0].data[0]
    } catch (err) {
      return {
        message: 'Could not reserve timeslot hold',
        error: err,
      }
    }
  }

  async function refreshHoldTimeslot(
    timeslot: TimeslotData | Timeslot,
    fullPremise: any,
    broadbandProduct: string | undefined,
    serviceType: string,
  ) {
    try {
      const params = {
        data: [{
          eventId: 'reserveAppointment',
          contextVar: {
            date: `${timeslot.date}`,
            appointmentWindowId: `${timeslot.appointmentWindowId}`,
            scheduleType: 'INSTALL',
            broadbandProduct: broadbandProduct?.replace(/[aA-zZ]/g, ''),
            serviceType,
            accountType: fullPremise?.type?.toUpperCase(),
            installationType: fullPremise?.installType || fullPremise?.install_type,
            region: fullPremise.borough,
          },
        }],
      }
      const { data } = await client.post<{ data: any[] }>('appointment/reserve', params)

      const reservedTimeslot = data.data[0].data[0]

      return reservedTimeslot.appointmentWindowData[0]
    } catch (err) {
      return {
        error: err,
        message: 'Could not refresh timeslot hold',
      }
    }
  }

  async function releaseHoldTimeslot(
    timeslot: TimeslotData | Timeslot,
    fullPremise: any,
    broadbandProduct: string | undefined,
    serviceType: string,
  ) {
    try {
      const params = {
        data: [{
          eventId: 'reserveAppointment',
          contextVar: {
            date: `${timeslot.date}`,
            appointmentWindowId: `${timeslot.appointmentWindowId}`,
            scheduleType: 'INSTALL',
            broadbandProduct: broadbandProduct?.replace(/[aA-zZ]/g, ''),
            serviceType,
            accountType: fullPremise?.type?.toUpperCase(),
            installationType: fullPremise?.installType,
            region: fullPremise.borough,
          },
        }],
      }
      const { data } = await client.post<{ data: any[] }>('appointment/reserve', params)

      return data.data[0].data[0].appointmentWindowData[0]
    } catch (err) {
      return {
        error: err,
        message: 'Could not release timeslot hold',
      }
    }
  }

  return {
    getTimeslots,
    getPendingTimeslots,
    rescheduleTimeslot,
    reserveTimeslot,
    refreshHoldTimeslot,
    releaseHoldTimeslot,
  }
}
