import React from 'react'
import {
  Box, Grid,
  Typography,
} from '@material-ui/core'
import { Create } from '@material-ui/icons'
import { PersonalDetailsFormData } from '../../PersonalDetailsForm/PersonalDetailsForm'
import theme from '../../../../../../styles/theme'

interface PersonalDetailsProps {
  data: Partial<PersonalDetailsFormData>;
  switchStep: ((e: string) => void);
}

const PersonalDetails = ({
  data, switchStep,
}: PersonalDetailsProps) => {
  const {
    firstName, lastName, email, phone,
  } = data

  return (
    <>
      <Box mb={2}>
        <Grid container justify="space-between">
          <Typography variant="h4">Your Details</Typography>
          <Create color="secondary" onClick={() => switchStep('checkout-details')}/>
        </Grid>
      </Box>
      <Typography
        variant="body1"
        color="primary"
        style={{
          display: 'flex',
          gap: theme.spacing(1),
        }}
      >
        <Box minWidth={theme.spacing(9)}>Name:</Box>
        <Box flexGrow={1}>{firstName} {lastName}</Box>
      </Typography>
      <Typography
        variant="body1"
        color="primary"
        style={{
          display: 'flex',
          gap: theme.spacing(1),
        }}
      >
        <Box minWidth={theme.spacing(9)}>Email:</Box>
        <Box
          flexGrow={1}
          style={{
            overflowWrap: 'anywhere',
          }}
        >
          {email}
        </Box>
      </Typography>
      <Typography
        variant="body1"
        color="primary"
        style={{
          display: 'flex',
          gap: theme.spacing(1),
        }}
      >
        <Box minWidth={theme.spacing(9)}>Mobile:</Box>
        <Box flexGrow={1}>{phone}</Box>
      </Typography>
    </>
  )
}

export default PersonalDetails
