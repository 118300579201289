import React, {
  useEffect, useRef, useState,
} from 'react'
import PhoneIcon from '../icons/PhoneIcon'
import WifiIcon from '../icons/WifiIcon'
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import ModalBox from '../../../../../ModalBox/ModalBox'
import { classes } from '../SummarySection.styles'
import {
  accountRegex,
  nameRegex, specialCharactersSanitizer,
} from '../../../../../../utils'
import TextInput from '../../../../../TextInput/TextInput'
import {
  FormContext, useForm,
} from 'react-hook-form'
import AttentionIcon from '../icons/AttentionIcon'
import Checkmark from '../icons/Checkmark'
import { cx } from 'linaria'
import {
  OrderOptions, useOrderContext,
} from '../../../../OrderContext'
import theme, { general } from '../../../../../../styles/theme'
import StyledButton from '../../../../../StyledButton/StyledButton'
import { useRouter } from 'next/router'
import createCRMApiClient from '../../../../../../api/CRMApi'
import marketingEvents from '../../../../../../utils/marketing/marketingEvents'
import { Info } from '@material-ui/icons'
import Wysiwyg from '../../../../../Wysiwyg/Wysiwyg'

interface FormData {
  accountId: string;
  lastNameOnCurrentProviderBill: string;
}

enum MatchStatus {
  REQUEST = 'matchRequest',
  CONFIRMATION = 'matchConfirmation',
  RESUBMIT = 'matchResubmit',
  FAILURE = 'matchFailure',
}

const OTSDetails = () => {
  const router = useRouter()
  const currentUrl = router.asPath
  const ref = useRef<null | HTMLDivElement>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    options, setOptions,
  } = useOrderContext()

  const {
    switchingConfirmation,
    switchingConfirmationError,
    OTSPhone,
    OTSMatchRequestError,
    OTSOptOut,
    OTSFormProps,
    OTSResubmitTriggered,
    butterCheckoutContent,
  } = options

  const methods = useForm<FormData>()

  const {
    handleSubmit, formState,
  } = methods

  const { isSubmitting } = formState

  const [
    confirmationModal,
    setConfirmationModal,
  ] = useState<boolean>(false)

  const [
    accountModal,
    setAccountModal,
  ] = useState<boolean>(false)

  const [
    switchingConfirmationCheck,
    setSwitchingConfirmationCheck,
  ] = useState<boolean>(switchingConfirmation)

  const [
    tooltipData,
    setTooltipData,
  ] = useState<any>()

  const [
    isSummaryPage,
    setIsSummaryPage,
  ] = useState<boolean>(false)

  const statusRef = useRef<MatchStatus>(MatchStatus.REQUEST)
  const resubmitTriggered = useRef<boolean>(false)

  const [
    status,
    setStatus,
  ] = useState<MatchStatus>(MatchStatus.REQUEST)

  useEffect(() => {
    if (currentUrl.includes('order/checkout/summary')) {
      setIsSummaryPage(true)
    } else {
      setIsSummaryPage(false)
    }
  }, [currentUrl])

  useEffect(() => {
    if (isSummaryPage) {
      setTimeout(() => {
        checkSessionStorage()
      }, 3000)
    }
  }, [isSummaryPage])

  // For Ga4 events
  useEffect(() => {
    if (isSummaryPage) {
      if (status === MatchStatus.CONFIRMATION) {
        marketingEvents.simpleEvent('OTS_successful_match', options)
      }

      if (status === MatchStatus.RESUBMIT) {
        marketingEvents.simpleEvent('OTS_retry', options)
      }

      if (status === MatchStatus.FAILURE) {
        marketingEvents.simpleEvent('OTS_failure', options)
      }
    }
  }, [
    isSummaryPage,
    status,
  ])

  useEffect(() => {
    resubmitTriggered.current = OTSResubmitTriggered
  }, [OTSResubmitTriggered])

  useEffect(() => {
    statusRef.current = status

    setOptions({
      ...options,
      OTSOptOut: false,
      switchingConfirmation: false,
      switchingConfirmationError: false,
    })

    if (status === MatchStatus.REQUEST) {
      setTimeout(() => {
        if (statusRef.current === MatchStatus.REQUEST) {
          console.warn('Timeout reached. No confirmation or failure detected / N2')
          if (resubmitTriggered.current) {
            setStatus(MatchStatus.FAILURE)
          } else {
            setStatus(MatchStatus.RESUBMIT)
          }
        }
      }, 120000)
    }
  }, [status])

  useEffect(() => {
    if (currentUrl.includes('order/checkout/summary')) {
      if (OTSMatchRequestError) {
        setStatus(MatchStatus.FAILURE)
        return
      }

      const intervalId: ReturnType<typeof setInterval> = setInterval(checkSessionStorage, 10000)
      const timeoutId: ReturnType<typeof setTimeout> = setTimeout(() => {
        clearInterval(intervalId)
        if (statusRef.current === MatchStatus.REQUEST) {
          console.warn('Timeout reached. No confirmation or failure detected.')
          if (resubmitTriggered.current) {
            setStatus(MatchStatus.FAILURE)
          } else {
            setStatus(MatchStatus.RESUBMIT)
          }
        }
      }, 60000)

      if (status !== MatchStatus.REQUEST) {
        clearInterval(intervalId)
        clearTimeout(timeoutId)
      }

      return () => {
        clearInterval(intervalId)
        clearTimeout(timeoutId)
      }
    }

    return undefined
  }, [
    OTSMatchRequestError,
    currentUrl,
    status,
  ])

  useEffect(() => {
    setSwitchingConfirmationCheck(switchingConfirmation)
  }, [switchingConfirmation])

  useEffect(() => {
    if (isMobile && switchingConfirmationError === true && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [
    switchingConfirmationError,
    isMobile,
  ])

  useEffect(() => {
    if (butterCheckoutContent) {
      setTooltipData(JSON.parse(butterCheckoutContent).summary.accountTooltip)
    }
  }, [butterCheckoutContent])

  const checkSessionStorage = () => {
    const matchResult = sessionStorage.getItem('ots-match-result')

    if (matchResult === 'residentialSwitchMatchConfirmation') {
      setStatus(MatchStatus.CONFIRMATION)
    } else if (matchResult === 'residentialSwitchMatchFailure') {
      if (resubmitTriggered.current) {
        setStatus(MatchStatus.FAILURE)
      } else {
        setStatus(MatchStatus.RESUBMIT)
      }
    } else if (matchResult === 'residentialSwitchMatchRequest') {
      setStatus(MatchStatus.REQUEST)
    } else {
      console.warn('Unexpected match result:', matchResult)
      if (resubmitTriggered.current) {
        setStatus(MatchStatus.FAILURE)
      } else {
        setStatus(MatchStatus.RESUBMIT)
      }
    }
  }

  async function fetchOtsRegistration(otsRegistration: any, correlationID: string, attempts = 0): Promise<void> {
    const maxAttempts = 5
    try {
      await sessionStorage.removeItem('ots-match-result')
      await otsRegistration.getOtsRegistrationResponse({ correlationID })
      await checkSessionStorage()

      if (statusRef.current === MatchStatus.REQUEST) {
        if (attempts < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, 10000))
          return fetchOtsRegistration(otsRegistration, correlationID, attempts + 1)
        }

        console.warn('Max retry attempts reached.')
        if (resubmitTriggered.current) {
          setStatus(MatchStatus.FAILURE)
        } else {
          setStatus(MatchStatus.RESUBMIT)
        }
      }
    } catch (err) {
      setStatus(MatchStatus.FAILURE)
      console.error('Error fetching OTS Registration Response:', err)
    }
  }

  const onSubmit = handleSubmit(async (data: FormData) => {
    marketingEvents.simpleEvent('OTS_retry_submit_click', options)
    const client = createCRMApiClient()
    const correlationID = sessionStorage.getItem('OTSCorrelationID')
    const {
      accountId, lastNameOnCurrentProviderBill,
    } = data
    resubmitTriggered.current = true
    await setOptions({
      ...options,
      OTSResubmitTriggered: true,
    })

    if (OTSFormProps && data) {
      setStatus(MatchStatus.REQUEST)
      const matchResponse: any = await client.otsform.post({
        ...OTSFormProps,
        account: accountId,
        lastName: lastNameOnCurrentProviderBill,
      })

      if (matchResponse && 'error' in matchResponse) {
        setOptions({
          ...options,
          OTSMatchRequestError: true,
          OTSResubmitTriggered: true,
        })
        console.error('Error fetching OTS Match Response')
        setStatus(MatchStatus.FAILURE)
        return
      }

      if (correlationID) {
        const otsRegistration = await client.otsRegistrationResponse
        setTimeout(() => {
          fetchOtsRegistration(otsRegistration, correlationID)
        }, 5000)
      } else {
        setStatus(MatchStatus.FAILURE)
        console.warn('No correlationID found in session storage')
      }
    } else {
      setStatus(MatchStatus.FAILURE)
      console.warn('OTSFormProps or FormData undefined')
    }
  })

  const openModal = () => {
    setConfirmationModal(!confirmationModal)
    if (!confirmationModal) {
      marketingEvents.simpleEvent('OTS_successful_match_learnMore_click', options)
    }
  }

  const handleInputBlur = (eventName: string, options: OrderOptions, validator: (value: string) => boolean) => (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value
    let isValid = false

    if (value && value.trim().length > 0) {
      isValid = validator(value)
    }

    if (isValid) {
      // Field is valid and complete, trigger GA event
      marketingEvents.simpleEvent(eventName, options)
    }
  }

  const handleSwitchingConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    if (status === MatchStatus.CONFIRMATION) {
      marketingEvents.simpleEvent(`OTS_successful_match_confirm_checkbox_${isChecked}`, options)
    }

    if (status === MatchStatus.RESUBMIT) {
      marketingEvents.simpleEvent(`OTS_retry_confirm_checkbox_${isChecked}`, options)
    }

    if (status === MatchStatus.FAILURE) {
      marketingEvents.simpleEvent(`OTS_failure_confirm_checkbox_${isChecked}`, options)
    }

    setOptions({
      ...options,
      OTSOptOut: status !== MatchStatus.CONFIRMATION && isChecked ? true : OTSOptOut,
      switchingConfirmation: isChecked,
      switchingConfirmationError: !isChecked,
    })

    if (status === MatchStatus.CONFIRMATION) {
      sessionStorage.setItem('ots-user-confirmation', isChecked ? 'true' : 'false')
    } else {
      sessionStorage.setItem('ots-user-confirmation', 'false')
    }
  }

  // Generic function to toggle modals
  const toggleModal = (modalSetter: React.Dispatch<React.SetStateAction<boolean>>, eventName?: string) => {
    modalSetter((prevState) => !prevState)
    if (eventName && !accountModal) {
      marketingEvents.simpleEvent(eventName, options)
    }
  }

  const switchingYourselfCheckbox = (
    <>
      <div ref={ref}/>
      <Box
        pt={3}
        pb={3}
        className={cx(
          classes.checkbox,
          switchingConfirmationError && classes.errorCheckbox,
        )}
      >
        <Box>
          {(status === MatchStatus.RESUBMIT || status === MatchStatus.REQUEST) && (
            <Typography variant="body1">Or you can continue your order without our help to switch.</Typography>
          )}
          {status === MatchStatus.FAILURE && (
            <Typography variant="body1">
              <b>You are still able to proceed with your order by clicking below.</b>
            </Typography>
          )}
        </Box>
        <FormControlLabel
          control={<Checkbox color="primary" checked={switchingConfirmationCheck} onChange={handleSwitchingConfirmation}/>}
          label={
            status === MatchStatus.CONFIRMATION ?
              'I confirm that I have read and understand the above and want to continue with my order.' :
              'I agree to cancel my contract with my current provider.'
          }
        />
      </Box>
      {switchingConfirmationError && (
        <Box className={classes.otsConfirmationError}>
          <AttentionIcon/>
          <Typography variant="caption">
            {status === MatchStatus.CONFIRMATION ?
              'Please confirm that you understand the switching implications to continue. If you have not received these yet and don\'t want to continue right now then we will call you in a few days to finalise and complete your order.' :
              'Please confirm that you agree to cancel your contract with your current provider yourself to proceed now with the order.'}
          </Typography>
        </Box>
      )}
    </>
  )

  const residentialSwitchMatchRequest = () => {
    return (
      <>
        <Grid container item className={cx(classes.otsContainer, classes.progressScreen)}>
          <CircularProgress size="lg" variant="indeterminate" thickness={5} color="secondary"/>
          <Box mt={2}>
            <Typography variant="h4" component="p" color="primary">
              Checking your details
            </Typography>
            <br/>
            <Typography variant="body1">
              We&apos;ll let you know as soon as we&apos;re done confirming your details with your current provider.
            </Typography>
          </Box>
        </Grid>
        {switchingYourselfCheckbox}
      </>
    )
  }

  const residentialSwitchMatchConfirmation = () => {
    return (
      <Grid container item className={classes.otsContainer}>
        <>
          <Checkmark className={classes.mainIcon}/>
          <Box mb={2} mt={2}>
            <Typography variant="h4" component="p">
              You&apos;re ready to switch to London&apos;s fastest full fibre broadband
            </Typography>
          </Box>
          <Grid container direction="column">
            <Typography variant="body1">Place your order to switch the following services from your current provider:</Typography>
            <Box pb={2} pt={2}>
              <Grid container>
                <Box mr={1}>
                  <WifiIcon/>
                </Box>
                <Typography variant="body1">
                  <b>Broadband</b>
                </Typography>
              </Grid>
              {OTSPhone && (
                <Box mt={2}>
                  <Grid container>
                    <Box mr={1}>
                      <PhoneIcon/>
                    </Box>
                    <Typography variant="body1">
                      <b>Phone</b>
                    </Typography>
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
          <div ref={ref}/>
          <Box>
            <Typography variant="body1">
              You&apos;ll be sent a letter or email from your current provider detailing any cancellation costs that will be incurred.
              <>
                <Link onClick={openModal} underline="always">
                  Find out more
                </Link>
                <ModalBox
                  visible={confirmationModal}
                  sync={openModal}
                >
                  <Box pb={2}>
                    <Typography variant="h3" component="p" color="textPrimary">
                      Sit back and relax, we will take it from here.
                    </Typography>
                  </Box>
                  <Box pb={3}>
                    <Typography variant="body1" color="textPrimary">We will deal directly with your current provider to organise the switch.</Typography>
                  </Box>
                  <Typography variant="body1" color="textPrimary">
                    Once you&apos;ve placed your order and booked your installation date for Community Fibre, we&apos;ll let your previous provider know and we&apos;ll keep you updated as we progress your switch.
                  </Typography>
                  <Box display="flex" justifyContent="center" pb={6} pt={2}>
                    <StyledButton color="primary" handleClick={openModal}>
                      Close
                    </StyledButton>
                  </Box>
                </ModalBox>
              </>
            </Typography>
          </Box>
          {switchingYourselfCheckbox}
        </>
      </Grid>
    )
  }

  const residentialSwitchMatchResubmit = () => {
    return (
      <>
        <Grid container item className={classes.otsContainer}>
          <FormContext {...methods}>
            <Container>
              <form onSubmit={onSubmit}>
                <AttentionIcon className={classes.mainIcon}/>
                <Box mb={2} mt={2}>
                  <Typography variant="h4" component="p">
                    Unfortunately your current provider&apos;s database can&apos;t match your details for switching.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="body1">Please double check the last name and provide your account number so we can try again.</Typography>
                  <br/>
                  <Typography variant="body1">You will be able to find these details in your last bill.</Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <TextInput
                    gridWidth={12}
                    label="Last name on current provider bill"
                    placeholder="Last name"
                    handleChange={specialCharactersSanitizer}
                    inputProps={{
                      onBlur: handleInputBlur('OTS_retry_lastName_completed', options, (value) => nameRegex.test(value)),
                    }}
                    required
                    validation={{
                      pattern: {
                        value: nameRegex,
                        message: 'Please use a valid name. Insert alphabetic characters only',
                      },
                      minLength: {
                        value: 1,
                        message: 'Minimum length is 3 characters',
                      },
                      maxLength: {
                        value: 25,
                        message: 'Maximum length is 25 characters',
                      },
                      validate: (value) => value.trim().length > 0 || 'Last Name is required and cannot be just spaces',
                    }}
                  />
                  <Typography variant="caption" color="primary">
                    You will need to use the same last name that&apos;s on the bill with your current provider.
                  </Typography>
                </Box>
                <Box mt={2} mb={3}>
                  <Box className={classes.toolTipWrapper}>
                    <Link
                      onClick={() => toggleModal(setAccountModal, 'OTS_Account_number_info')}
                      underline="always"
                      color="secondary"
                    >
                      <Info style={{ marginLeft: 'auto' }}/>
                    </Link>
                    <ModalBox
                      className={classes.toolTipModal}
                      px={3.5}
                      visible={accountModal}
                      sync={() => toggleModal(setAccountModal)}
                    >
                      {tooltipData?.title &&
                      <Typography variant="h4" component="p" color="primary">
                        {tooltipData.title}
                      </Typography>}
                      {tooltipData?.body &&
                      <Wysiwyg
                        body={tooltipData.body} variant="body1"
                      />}
                      <Box display="flex" justifyContent="center" pb={5} pt={1}>
                        <StyledButton color="primary" handleClick={() => toggleModal(setAccountModal)}>
                          Close
                        </StyledButton>
                      </Box>
                    </ModalBox>
                  </Box>
                  <Box>
                    <TextInput
                      gridWidth={12}
                      label="Account number with current provider"
                      placeholder="e.g. A31359"
                      id="accountId"
                      type="text"
                      required
                      inputProps={{
                        onBlur: handleInputBlur('OTS_retry_account_number_completed', options, (value) => accountRegex.test(value)),
                      }}
                      handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // Use a regular expression to only allow alphanumeric characters
                        const sanitisedValue = e.target.value.replace(/[^a-zA-Z0-9_#-]/g, '')
                        e.target.value = sanitisedValue
                      }}
                      validation={{
                        pattern: {
                          value: accountRegex,
                          message: 'Please use a valid Account Number',
                        },
                        minLength: {
                          value: 3,
                          message: 'Minimum length is 3 characters',
                        },
                        maxLength: {
                          value: 25,
                          message: 'Maximum length is 25 characters',
                        },
                        validate: (value) => value.trim().length > 0 || 'Account Number is required and cannot be just spaces',
                      }}
                    />
                  </Box>
                  <Typography variant="caption" color="primary">
                    This can be found on a recent bill from your current provider.
                  </Typography>
                </Box>
                <StyledButton type="submit" variant="outlined" color="secondary" size="large" id="step-1" disabled={isSubmitting}>
                  <Typography color="secondary" variant="h5" component="p" className={general.font.weight.bold}>
                    Submit details
                  </Typography>
                  {isSubmitting && <CircularProgress className={classes.loading} size="1.5rem" color="inherit"/>}
                </StyledButton>
              </form>
            </Container>
          </FormContext>
        </Grid>
        {switchingYourselfCheckbox}
      </>
    )
  }

  const residentialSwitchMatchFailure = () => {
    return (
      <Grid container item className={classes.otsContainer}>
        <Box mb={2}>
          <AttentionIcon className={classes.mainIcon}/>
          <Box mb={2} mt={2}>
            <Typography variant="h4" component="p">
              We are unable to do the switching from your current provider
            </Typography>
          </Box>
          <Typography variant="body1">
            It may be that a switch was already started with another provider or there is an issue with your current provider database.
          </Typography>
          {switchingYourselfCheckbox}
        </Box>
      </Grid>
    )
  }

  return (
    <>
      {status === MatchStatus.REQUEST && residentialSwitchMatchRequest()}
      {status === MatchStatus.CONFIRMATION && residentialSwitchMatchConfirmation()}
      {status === MatchStatus.RESUBMIT && residentialSwitchMatchResubmit()}
      {status === MatchStatus.FAILURE && residentialSwitchMatchFailure()}
    </>
  )
}

export default OTSDetails
