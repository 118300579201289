import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { ContainerWithCurve } from '../../styles/ContainerWithCurve'
import TypographyCMS, { createTypographyProps } from '../Typography/Typography'
import MediaImage from '../Media/MediaImage'
import classesOld from '../Packages/Packages.styles'
import { BOX } from '../../styles/theme'
import {
  getProductTerm, getSpeed,
} from '../../utils/getProductDetails'
import { useOrderContext } from '../NewOrderFlow/OrderContext'
import { cx } from 'linaria'
import { PackageProps } from '../Packages/Package'
import Features from '../Packages/Features'
import Price from '../Packages/Price'
import { AnimationLine } from '../Packages/AnimationLine'
import { useRouter } from 'next/router'
import { Flag } from '../Packages/Flag'
import CallsRequirements from '../Products/CallsRequirements'
import {
  HobsProduct, ProductOffering,
} from '../../api/Packages'
import createCRMApiClient from '../../api/CRMApi'
import PackageCTA from '../Packages/PackageCTA'
import { withStyles } from '@material-ui/core/styles'
import { PackageStyles } from '../Packages/Packages.styles2'
import Wrapper from '../Wrapper/Wrapper'
import { addToCartOptions } from '../Packages/Helpers'

export enum HobsAddonsInBundleType {
  VOICE = 'Calling_Product_bundle',
  WIER = 'Wier_Product_bundle',
}

export const checkIfAddonExists = (
  product: any, type: HobsAddonsInBundleType.VOICE | HobsAddonsInBundleType.WIER,
) => {
  if (product.length > 0 && type === 'Calling_Product_bundle') {
    return product?.find((addon: HobsProduct) => addon.productOfferingId === HobsAddonsInBundleType.VOICE)
  }

  if (product.length > 0 && type === 'Wier_Product_bundle') {
    return product?.find((addon: HobsProduct) => addon.productOfferingId === HobsAddonsInBundleType.WIER)
  }

  return null
}

export const checkIfWierExists = (bundle: PackageProps) => {
  const productOffering = bundle?.productOffering as ProductOffering[]
  return productOffering && Object.values(productOffering)
    .some((e: ProductOffering) => e.productOfferingId.toLowerCase()
      .includes('wier'))
}

export const checkIfVasExists = (bundle: PackageProps) => {
  const productOffering = bundle?.productOffering as ProductOffering[]
  return productOffering && Object.values(productOffering)
    .some((e: ProductOffering) => e.productOfferingId === 'NORTON360P') // VAS check temporary tied only to Norton
}

export const checkIfVoiceExists = (bundle: PackageProps) => {
  const productOffering = bundle?.productOffering as ProductOffering[]
  return productOffering && Object.values(productOffering)
    .some((e: ProductOffering) => e.productOfferingId.toLowerCase()
      .includes('calling') || e.productOfferingId.toLowerCase()
      .includes('voice'))
}

const Bundle = withStyles(PackageStyles)(({
  selected,
  on_click_package,
  product,
  flip,
  term,
  noContainer,
  button,
  button_secondary,
  flag,
  footnote,
  price_guidance,
  title,
  display_image,
  display_name,
  display_subtitle,
  simple_bullets,
  bullets_back,
  anchor,
  mt,
  classes,
  price,
  old_price,
  hide_strikethrough,
}: PackageProps) => {
  const {
    options, setOptions,
  } = useOrderContext()

  const [
    open,
    setOpen,
  ] = useState(false)

  const {
    channel,
  } = options

  const toggleReadMore = (event: React.ChangeEvent<{}>) => {
    event.stopPropagation()
    event.preventDefault()
    setOpen(!open)
  }

  const routerPath = useRouter()
  const client = createCRMApiClient()
  const voiceInBundle = checkIfVoiceExists(product!)
  const priceGuidance = price_guidance || '/month*'

  async function handleBundleClick(e: React.ChangeEvent<{}>) {
    e.stopPropagation()

    const bundleObj = {
      product: product!,
      options,
      client,
      channel,
    }

    if (on_click_package) {
      on_click_package(product, channel)
      return
    }

    if (button?.url) {
      routerPath.push(routerPath.asPath + button?.url, undefined, { shallow: true })
    }

    const bundleOptions = await addToCartOptions(bundleObj)
    setOptions(bundleOptions)
  }

  const flipCard = open ?
    cx(classesOld.flipcard.inner, classesOld.flipcard.cardFlipped) :
    cx(classesOld.flipcard.inner)

  const renderCard = (children: React.ReactNode, side: 'front' | 'back') => {
    return (
      <Box
        className={cx(classes.bundle, classes.commonPackage, side === 'front' ? classesOld.flipcard.front : classesOld.flipcard.back)}
        {...BOX}
      >
        <Box>
          <Box>
            {flag &&
            <Flag
              text_color={flag?.text_color}
              background_color={flag?.background_color}
              text={flag?.flag_text}
              position={side === 'front' ? 'left' : 'right'}
            />}
            <Box flexGrow={1} className={classes.bundleContent}>
              <Box gridArea="heading" mb={3}>
                {display_name &&
                <TypographyCMS
                  text={display_name}
                  variant="h3"
                  color="primary"
                  margin_bottom={1}
                />}
                {display_subtitle &&
                <TypographyCMS
                  text={display_subtitle}
                  variant="subtitle1"
                  color="primary"
                  lineHeight="1.4"
                />}
              </Box>
              <Box
                gridArea="price"
                mb={3}
              >
                <Price
                  product={product!}
                  price_guidance={priceGuidance}
                  term={term}
                  discount_full
                  align="left"
                  price={price}
                  old_price={old_price}
                  hide_strikethrough={hide_strikethrough}
                />
                <Box mt={2} minWidth={250}>
                  <AnimationLine speed={getSpeed(product, 'UPLOAD_SPEED')} startSpeedAnimation fileSize="100"/>
                </Box>
              </Box>
              {simple_bullets &&
              <Box
                gridArea="features"
                pl={{
                  xs: 0,
                  md: 0,
                  lg: 5,
                }}
                mb={{
                  xs: 4,
                  md: 4,
                  lg: 0,
                }}
              >
                {children}
              </Box>}
              <PackageCTA
                product={product}
                button={button}
                button_secondary={button_secondary}
                id={product?.productOfferingId}
                disabled={false}
                selected={selected!}
                handleClick={handleBundleClick}
                handleSecondaryClick={flip ? toggleReadMore : (e: React.ChangeEvent<{}>) => e.preventDefault()}
              />
            </Box>
          </Box>
          {voiceInBundle && selected && getProductTerm(options.bundle) === getProductTerm(product) &&
          <CallsRequirements margins/>}
        </Box>
        {display_image &&
          <Box
            className={classes.displayImage}
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth={{
              xs: 0,
              md: 290,
            }}
            overflow="hidden"
            maxHeight={{
              md: 290,
              lg: '100%',
            }}
          >
            <MediaImage
              src={display_image?.src}
              mobile_src={display_image?.mobile_src}
              alt={display_image?.alt}
              width={290}
              height={260}
              full_height
              full_width
              align="top"
              cover
            />
          </Box>}
      </Box>
    )
  }

  const renderCards = () => {
    return (
      <Box id={anchor} className={flipCard}>
        {flip && renderCard(
          <Features
            bullet_size="large"
            simple_bullets={bullets_back}
            text_color="textPrimary"
          />,
          'back',
        )}
        {renderCard(
          <Features
            bullet_size="large"
            simple_bullets={simple_bullets}
            bullets_with_icons={product?.bullets_with_icons}
            text_color="textPrimary"
          />,
          'front',
        )}
      </Box>
    )
  }

  if (noContainer) {
    return (
      <Box component={Wrapper} mt={mt}>
        { renderCards() }
      </Box>
    )
  }

  return (
    <ContainerWithCurve id={anchor} py={10} gradient>
      <Wrapper>
        {title && <TypographyCMS {...createTypographyProps(title)}/>}
        { renderCards() }
        {footnote && (<TypographyCMS {...createTypographyProps(footnote)}/>)}
      </Wrapper>
    </ContainerWithCurve>
  )
})

export default Bundle
