import React, {
  useState,
  useRef,
  useEffect,
} from 'react'
import { useRouter } from 'next/router'
import classesOld from './Packages.styles'
import {
  Box, PropTypes, Typography,
} from '@material-ui/core'
import StyledButton, { ButtonProps } from '../StyledButton/StyledButton'
import { AnimationLine } from './AnimationLine'
import { cx } from 'linaria'
import {
  useOrderContext,
} from '../NewOrderFlow/OrderContext'
import useIntersectionObserver from '../EnquiryBottomBar.js/EnquiryHook'
import {
  HobsProduct,
} from '../../api/Packages'
import createCRMApiClient from '../../api/CRMApi'
import CheckIcon from '@material-ui/icons/Check'
import TypographyCMS, { TypographyProps } from '../Typography/Typography'
import Features, {
  BulletModalProps,
} from './Features'
import Price from './Price'
import {
  getDiscountPercent,
} from '../../utils/getProductDetails'
import { Flag } from './Flag'
import {
  BOX, fontWeights, COLORS,
} from '../../styles/theme'
import { withStyles } from '@material-ui/core/styles'
import { PackageStyles } from './Packages.styles2'
import { addToCartOptions } from './Helpers'
import { ChannelType } from '../../utils/commonEnums'
import { VoucherProps } from '../PaidMediaLandingPage/MediaPageHero/MediaPageVoucher'
import { MediaImageProps } from '../Media/MediaImage'
import StrikethroughName, { StrikethroughNameProps } from '../StrikethroughName/StrikethroughName'
export interface PackageProps extends HobsProduct {
  name?: string;
  price?: string;
  old_price?: any;
  simple_bullets?: string;
  bullets_back?: string;
  bullets_with_icons?: BulletModalProps[];
  bullet_align?: string;
  upload_speed?: string;
  download_speed?: string;
  hide?: boolean;
  out_of_contract_copy?: string;
  file_size?: number;
  button?: ButtonPropsExtended;
  button_secondary?: ButtonPropsExtended;
  readless_text?: string;
  flag?: FlagProps;
  product?: PackageProps;
  width?: string;
  startSpeedAnimation?: boolean;
  discount?: string;
  on_click_package?: Function;
  display_subtitle?: string;
  display_title?: string;
  selected?: boolean;
  term?: number;
  flip?: boolean;
  align?: PropTypes.Alignment;
  bundles?: PackageProps[];
  anchor?: string;
  title?: TypographyProps;
  footnote?: TypographyProps;
  type?: string;
  noContainer?: boolean;
  wier_id?: string;
  contract_length?: number;
  show_free_months?: boolean;
  mt?: number;
  classes?: any;
  hide_strikethrough?: boolean;
  usage_description?: string;
  speed?: string;
  display_image?: MediaImageProps;
  voucher?: VoucherProps[];
  promo_code?: string;
  free_months_with_promo_code?: string;
  after_promo_copy?: string;
  strikethrough_name?: StrikethroughNameProps;
  name_first_line?: string;
  name_second_line?: string;
  setDisableButton?: Function;
  disabledButton?: boolean;
  price_guidance?: string;
  free_set_up_copy?: string;
  activeTermLength?: number;
}
export interface ButtonPropsExtended extends ButtonProps {
  text?: string;
}
export interface FlagProps {
  flag_text: string;
  subtitle?: string;
  background_color?: string;
  text_color?: string;
}

const Package = withStyles(PackageStyles)(({
  price,
  old_price,
  simple_bullets,
  bullets_back,
  out_of_contract_copy,
  bullets_with_icons,
  flag,
  upload_speed,
  file_size,
  hide,
  button,
  product,
  startSpeedAnimation,
  on_click_package,
  display_name,
  strikethrough_name,
  name_first_line,
  name_second_line,
  selected,
  term,
  button_secondary,
  flip,
  align = 'center',
  show_free_months,
  classes,
  hide_strikethrough,
  readless_text,
  voucher,
  promo_code,
  free_months_with_promo_code,
  after_promo_copy,
  setDisableButton,
  disabledButton,
  price_guidance,
  free_set_up_copy,
  activeTermLength,
}: PackageProps) => {
  const client = createCRMApiClient()
  const fileSize = file_size ? file_size : 500
  const routerPath = useRouter()

  const {
    options, setOptions,
  } = useOrderContext()

  const [
    open,
    setOpen,
  ] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [activeTermLength])

  const toggleReadMore = (event: React.ChangeEvent<{}>) => {
    event.stopPropagation()
    event.preventDefault()
    setOpen(!open)
  }

  const handleClick = async (e: React.ChangeEvent<{}>) => {
    e.stopPropagation()

    if (setDisableButton) {
      setDisableButton(true)
    }

    const channel = window.location.href.includes('utm_medium=affiliate') ? ChannelType.AFFILIATE : ChannelType.DIGITAL
    setOptions({
      ...options,
      channel,
    })

    const packageObj = {
      product: product!,
      options,
      client,
      channel,
      hide_strikethrough,
      voucher,
      promoToApply: promo_code,
    }

    if (on_click_package) {
      on_click_package(product, channel, hide_strikethrough)
      return
    }

    if (button?.url && button?.target !== 'blank') {
      routerPath.push(routerPath.asPath + button?.url, undefined, { shallow: true })
    }

    const packageOptions = await addToCartOptions(packageObj)
    setOptions(packageOptions)
  }

  const flipCard = open ?
    cx(classesOld.flipcard.inner, classesOld.flipcard.cardFlipped) :
    cx(classesOld.flipcard.inner)

  const packageCards = useRef<any>(product?.productOfferingId)
  const [isVisible] = useIntersectionObserver({
    elementRef: packageCards,
  })
  const discount_percent = getDiscountPercent(product!)
  const getAnnualSaving = (price: string, discount: { percent: number; amount: number }): string => {
    const total = Number(price) / 100 * discount.percent * discount.amount
    return `Save £${total.toFixed(2)
      .toString()}`
  }

  const showstrikethroughName = strikethrough_name && Object.values(strikethrough_name).length > 0 && name_first_line && name_second_line
  const leftSpacingCalc = strikethrough_name?.name_image?.width ? (strikethrough_name?.name_image?.width / 8) + 1 : 0
  const outOfContractPrice = out_of_contract_copy || 'Just £4* more when out of contract'
  const priceGuidance = price_guidance || '/month*'
  const preconfiguredFlag = () => {
    if (options?.promoToApply! && !promo_code) {
      return {
        text: 'First month free',
        position: 'right',
        background_color: `${COLORS.tertiary}`,
        text_color: `${COLORS.primary}`,
      }
    }

    return null
  }

  const cardContent = () => {
    return (
      <>
        {showstrikethroughName &&
        <>
          <Box display="flex">
            <StrikethroughName {...strikethrough_name}/>
            <Typography variant="h4" style={{ color: `${strikethrough_name?.color}` }}>{name_first_line}</Typography>
          </Box>
          <TypographyCMS
            variant="h4"
            margin_bottom={1.5}
            margin_left={leftSpacingCalc}
            text={name_second_line}
            color="primary"
          />
        </>}
        {display_name && !showstrikethroughName &&
          <TypographyCMS
            align={align}
            variant="h4"
            margin_bottom={1.5}
            text={display_name}
            color="primary"
          />}
        <Price
          price={promo_code && free_months_with_promo_code ? '0' : price}
          old_price={old_price}
          price_guidance={promo_code && free_months_with_promo_code ? free_months_with_promo_code : priceGuidance}
          product={product!}
          align={align}
          term={term}
          discount_full
          show_free_months={show_free_months}
          hide_strikethrough={hide_strikethrough}
          after_price_copy={after_promo_copy}
        />
        <Box mt={2}>
          <AnimationLine
            fileSize={fileSize}
            speed={upload_speed}
            startSpeedAnimation={startSpeedAnimation && isVisible}
          />
        </Box>
        <Box flexGrow="1">
          <Features
            mt={2}
            bullets_with_icons={bullets_with_icons}
            simple_bullets={simple_bullets}
            bullets_crm={product} // Setup fee
            free_set_up_copy={free_set_up_copy}
            bullet_size="large"
            text_color="textPrimary"
          />
        </Box>
        { cardCTA() }
      </>
    )
  }

  const cardFront = () => {
    return (
      <Box
        className={cx(classes.package, classes.commonPackage, classesOld.flipcard.front)}
        {...BOX}
      >
        {preconfiguredFlag() ?
          <Flag
            {...preconfiguredFlag()}
            position={preconfiguredFlag()?.position as 'left' | 'right'}
          /> :
          ((flag && flag.flag_text) || discount_percent) &&
          <Flag
            text_color={flag?.text_color}
            background_color={flag?.background_color}
            text={discount_percent ? getAnnualSaving(price!, discount_percent) : flag?.flag_text}
            position="right"
            subtitle={flag?.subtitle}
          />}
        {cardContent()}
      </Box>
    )
  }

  const cardBack = () => {
    return (
      <Box
        className={cx(classes.package, classes.commonPackage, classesOld.flipcard.back)}
        {...BOX}
      >
        {preconfiguredFlag() ?
          <Flag
            {...preconfiguredFlag()}
            position={preconfiguredFlag()?.position as 'left' | 'right'}
          /> :
          ((flag && flag.flag_text) || discount_percent) &&
          <Flag
            text_color={flag?.text_color}
            background_color={flag?.background_color}
            text={discount_percent ? getAnnualSaving(price!, discount_percent) : flag?.flag_text}
            position="left"
            subtitle={flag?.subtitle}
          />}
        {display_name &&
          <TypographyCMS align={align} variant="h4" margin_bottom={1.5} text={display_name} color="primary"/>}
        <Box flexGrow="1">
          <Features
            simple_bullets={bullets_back}
            bullets_crm={product}
            bullet_out_of_contract_price={outOfContractPrice}
            bullet_size="large"
            mt={3}
            text_color="textPrimary"
          />
        </Box>
        { cardCTA(readless_text || 'Twist back') }
      </Box>
    )
  }

  const cardCTA = (secondaryButtonText?: string) => {
    if ((button && button.text) || (button_secondary && button_secondary.text)) {
      return (
        <Box component="nav" mt={4} width="100%" display="flex" flexDirection="column" alignItems="center">
          {button && button.text &&
            <StyledButton
              color="primary"
              handleClick={handleClick}
              isFullWidth
              url={button.url}
              target={button.target}
              disabled={selected || disabledButton}
              className={cx(selected && classesOld.selected)}
            >
              {selected && <CheckIcon/>}
              {selected ? 'Selected' : button.text}
            </StyledButton>}
          {button_secondary && button_secondary.text &&
            <button
              type="button"
              onClick={flip ? toggleReadMore : (e: React.ChangeEvent<{}>) => e.preventDefault()}
              className={classesOld.twistButton}
            >
              <Typography
                variant="body1"
                color="secondary"
                style={{
                  textDecoration: 'underline',
                  fontWeight: fontWeights.bold,
                }}
              >
                {secondaryButtonText || button_secondary.text}
              </Typography>
            </button>}
        </Box>
      )
    }

    return null
  }

  return (
    <div className={cx(hide ? classesOld.hide : flipCard)} ref={packageCards} id={product?.anchor}>
      { cardFront() }
      { flip && cardBack() }
    </div>
  )
})

export default Package
